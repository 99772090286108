/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/subseven/tongay@master/formValidation.min.js
 * - /gh/subseven/tongay@master/formValidation_bootstrap.min.js
 * - /gh/subseven/tongay@master/animations_css3.min.js
 * - /gh/subseven/tongay@master/dvs.app.min.js
 * - /gh/subseven/tongay@master/ie10-viewport-bug-workaround.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
